import request from '@/utils/request'


// 查询版本升级列表
export function listVersionInfo(query) {
  return request({
    url: '/appversion/version/info/list',
    method: 'get',
    params: query
  })
}

// 查询版本升级分页
export function pageVersionInfo(query) {
  return request({
    url: '/appversion/version/info/page',
    method: 'get',
    params: query
  })
}

// 查询版本升级详细
export function getVersionInfo(data) {
  return request({
    url: '/appversion/version/info/detail',
    method: 'get',
    params: data
  })
}

// 新增版本升级
export function addVersionInfo(data) {
  return request({
    url: '/appversion/version/info/add',
    method: 'post',
    data: data
  })
}

// 修改版本升级
export function updateVersionInfo(data) {
  return request({
    url: '/appversion/version/info/edit',
    method: 'post',
    data: data
  })
}

// 删除版本升级
export function delVersionInfo(data) {
  return request({
    url: '/appversion/version/info/delete',
    method: 'post',
    data: data
  })
}
